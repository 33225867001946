// reactstrap components
import React, { useEffect, useState } from "react";

import Container from "reactstrap/lib/Container";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from "reactstrap";

function Event({ text, location }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  useEffect(() => {
    const common = {
      containment: "#bgndVideo2",
      autoPlay: true,
      mute: true,
      startAt: 0,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "nwJH1uKt4Zk",
        ...common,
      },
    ];
    if (size) {
      window.jQuery("#bgndVideo2").YTPlaylist(videos, false);
    }
  }, []);

  return (
    <div className="section pt-0" style={{ backgroundColor: !size ? "#000" : "transparent" }}>
      {size && (
        <div
          style={{
            background: "#000",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: -100,
            marginLeft: "auto",
            marginRight: "auto",
            pointerEvents: "none",
            // marginTop: "3.5rem",
          }}
        >
          <div
            id="bgndVideo2"
            className="section player"
            style={{ background: "transparent" }}
          ></div>
        </div>
      )}
      {size && <div className="overlay-primary" />}
      <div className="page-header" style={{ background: "transparent" }} data-parallax={true}>
        <Container>
          <Row className="text-700 justify-content-center" style={{ marginTop: "2rem" }}>
            <Col lg={6} className="mb-5">
              <h1 className="text-700 text-primary">Why Attend?</h1>
              <hr />
              <h5 className="text-400 text-white">
                Step into a world of collaboration and innovation as you join 200+ senior leaders
                from a spectrum of industries at our exclusive summit. Designed to address today’s
                most pressing business challenges, our thoughtfully curated agenda will empower you
                to drive impactful change and foster growth within your organization and industry.
              </h5>
              <Button
                href="/delegates/3"
                className="btn my-2 text-center px-5 rounded-0"
                color="primary"
                size="md"
              >
                <b>Learn More</b>
              </Button>
            </Col>
            <Col lg={6}>
              <h1 className="text-700 text-primary">Why Sponsor?</h1>
              <hr />
              <h5 className="text-400 text-white">
                Looking to fuel your business development for 2025 and beyond? Partner with us to
                connect directly with senior decision-makers in North Africa’s financial services
                industry. Showcase your expertise, present innovative FinTech solutions, and build
                impactful relationships with key buyers actively seeking new opportunities.
              </h5>
              <Button
                href="/partners"
                className="btn my-2 text-center px-5 rounded-0"
                color="primary"
                size="md"
              >
                <b>BECOME A SPONSOR</b>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Event;
