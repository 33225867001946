import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import S2 from "./svgs/s2";
import S5 from "./svgs/s5";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import S3 from "./svgs/s3";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Navigate Digital Transformation and Sustainable Innovation",
      description:
        "Transformation extends beyond technology to create a more inclusive, resilient, and sustainable financial system. Learn how AI, advanced analytics, and personalized platforms are redefining customer experiences with frictionless and intuitive services.",
      image: <S2 />,
    },
    {
      title: "Cybersecurity in the Digital Era",
      description:
        "As the risk of cyberattacks escalates, gain insights into cutting-edge cybersecurity strategies and technologies to safeguard financial data and ensure trust in an interconnected ecosystem.",
      image: <S3 />,
    },
    {
      title: "Cross-Border Fintech Regulation",
      description:
        "Unpack the complexities of harmonizing global standards to foster financial inclusion and streamline cross-border fintech operations in a rapidly globalizing market.",
      image: <S8 />,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" text-500 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2.3rem" : "1.5rem" }}
              >
                <b>
                  Navigating the Future of Finance through
                  <br /> Digital Transformation and Sustainable Innovation
                </b>
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                As the financial sector faces the dual imperatives of digital transformation and
                sustainable innovation. The 29th Edition of Finnovex North Africa, set for April 23,
                2025, in Cairo, Egypt, is a premier gathering of visionary leaders, innovators, and
                policymakers from across the region. With the theme “Navigating the Future of
                Finance through Digital Transformation and Sustainable Innovation,” the summit aims
                to redefine the financial landscape by fostering impactful conversations and
                collaborative solutions.
                <br />
                <br />
                This landmark event will spotlight how financial institutions can leverage
                cutting-edge technology to accelerate innovation, embed sustainability into their
                core operations, and create a resilient ecosystem for businesses and society alike.
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              <div className="container-fluid row pr-0">
                {content.map((s, index) => (
                  <Col className="text-center  p-2 px-0" lg={12} xs={12}>
                    <ScrollAnimation
                      animateIn={"fadeInUp"}
                      animateOnce={true}
                      duration={1.5 + index / 8}
                    >
                      <div className="pt-4 px-2 stat-div pb-2" style={{ minHeight: 270 }}>
                        {s.image}

                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-4"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </div>
                    </ScrollAnimation>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
