import { token } from "constant";
import { apiURL } from "constant";
import React from "react";
import Slider from "react-slick";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner } from "reactstrap";
import { size } from "./common";

function Register({ title, titleHide }) {
  const [details, setDetails] = React.useState({
    type: "REGISTER",
    optIn: true,
    privacyPolicy: true,
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const onChangeDetailsUdf = (e) => {
    setDetails({ ...details, udf: { ...details.udf, [e.target.name]: e.target.value } });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    if (reg.test(details.email)) {
      setLoading(false);
      setError("Please enter your business email only");
      return null;
    }
    fetch(`${apiURL}/setDelegateData`, {
      method: "POST",
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setDisable(true);
        setSuccess("Thank you for your request. We'll get back to you shortly.");
      })
      .catch((err) => {
        setLoading(false);
        setDisable(false);
        setError("Something went wrong please try again");
      });
  };
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <div
      style={{
        padding: "1rem 0",
        background: "transparent",
      }}
    >
      <Container>
        <Row>
          {!titleHide && (
            <Col lg={12}>
              <h1 className="text-center text-white text-900 pb-4 text-uppercase">
                <b>{title}</b>
              </h1>
            </Col>
          )}
          <Col lg={6}>
            <h5 className="text-400 text-white mb-5">
              As an Industry Pioneer in launching a successful and all-inclusive event platform to
              cater to the need of both our in-person and online community! Our priority to ensure
              the best of both worlds as we serve you opportunities for networking; connection to
              authentic learning content, goodies for your taking at Finnovex North Africa 2025!
              <br /> This Edition will be an exclusive opportunity for accelerated Banking and
              Financial Services solutions hub to -
            </h5>
            <h5 className="text-400 text-white">
              <ul>
                <li>
                  MEET with the regional, international banks and fintech decision-making executives
                  who are trend setting the tone for the future of financial world
                </li>
                <li>
                  LEARN how to evaluate, develop, deploy and customize the banking and financial
                  services technologies to improve your overall business processes
                </li>
                <li>
                  HEAR first-hand from customers (existing and potential) on the challenges they
                  face across the entire value chain of financial processes
                </li>
                <li>
                  TAKEAWAY lessons learned, valuable case studies and key insights from peers to
                  apply instantly within your operations
                </li>
                <li>
                  CONNECT with Fintech solution management, development, support and consulting
                  experts
                </li>
                <li>
                  EVALUATES and understand how various comprehensive suite of applications can
                  optimize your business needs
                </li>
                <li>
                  VISIT all our live experience centres and real solution demos in-person and
                  online. This would help you better understand the latest technology-driven
                  approach for go to market strategy that can help your business embark the journey
                  of excellence.
                </li>
                <li>
                  CROWNING Victory at our Regional Excellence Awards to recognise and applaud
                  pace-setting industry merit.
                </li>
              </ul>
            </h5>
          </Col>
          <Col className="mx-auto mb-5 py-5" lg={6}>
            <Container className="my-auto text-white text-uppercase text-400">
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row>
                  <Col lg={6} className="my-2">
                    <label>First Name*</label>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Last Name*</label>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Job Title*</label>
                    <Input
                      placeholder="Job Title"
                      type="text"
                      name="title"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Company*</label>
                    <Input
                      placeholder="Company"
                      type="text"
                      name="company"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Business Email*</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Phone*</label>
                    <Input
                      placeholder="Phone"
                      type="text"
                      name="phone"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Country*</label>
                    <Input
                      placeholder="Country"
                      type="text"
                      name="country"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Promo Code</label>
                    <Input
                      placeholder="Promo"
                      type="text"
                      name="promo"
                      onChange={onChangeDetails}
                    />
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Interested In</label>
                    <Input
                      type="select"
                      name="interest"
                      id=""
                      onChange={onChangeDetailsUdf}
                      required
                    >
                      <option>Select</option>
                      <option>Delegate Pass</option>
                      <option>Download Delegate List</option>
                      <option>Speaking Opportunity</option>
                      <option>Sponsoring the Summit</option>
                      <option>Booking an Exhibition Space</option>
                      <option>Media Partnership</option>
                    </Input>
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Where did you hear about us?</label>
                    <Input type="select" name="heardFrom" id="" onChange={onChangeDetailsUdf}>
                      <option>Select</option>
                      <option>Email</option>
                      <option>LinkedIn</option>
                      <option>Twitter</option>
                      <option>Facebook</option>
                      <option>Instagram</option>
                      <option>News/Other Websites</option>
                      <option>Referral</option>
                    </Input>
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Which other country/region would you like us to host FINNOVEX?</label>
                    <Input
                      placeholder="Country"
                      type="text"
                      name="hostCountry"
                      onChange={onChangeDetailsUdf}
                      required
                    />
                  </Col>

                  <Col lg="12" className="mt-3">
                    By submitting this form, I provide my consent to Exibex (Organizer) to send me
                    latest updates via email/Phone call/ with information related to our series of
                    summit and I provide my consent that by registering as a delegate, The details
                    shared pertaining not limited to your email and other contact details with
                    Exibex , Event Organiser has your consent in sharing details with their partners
                    and sponsors.
                  </Col>
                </Row>

                <Button
                  block
                  className="btn text-white text-700 mt-5"
                  color="primary"
                  size="lg"
                  type="submit"
                  disabled={loading || disable}
                >
                  Submit
                  {loading ? (
                    <span>
                      {" "}
                      <Spinner color="warning" size="sm" />
                    </span>
                  ) : (
                    ""
                  )}
                </Button>
              </Form>
            </Container>
            {error ? (
              <Alert
                color="danger"
                isOpen={true}
                fade={true}
                className="text-center"
                style={{ marginTop: "1rem" }}
              >
                {error}
              </Alert>
            ) : (
              ""
            )}
            {success ? (
              <Alert
                color="success"
                isOpen={true}
                fade={true}
                className="text-center"
                style={{ marginTop: "1rem" }}
              >
                {success}
              </Alert>
            ) : (
              ""
            )}
            {/* </Card> */}
          </Col>
          <Col lg={12}>
            <div style={{ width: "90%" }} className="mx-auto">
              <h2 className="text-white text-700 mb-4">Testimonials</h2>
              <Slider {...settings}>
                {testimonials.map((t) => (
                  <div>
                    <h3
                      className="text-white text-400"
                      style={{ fontSize: size ? "20px" : "16px" }}
                    >
                      <i class="fa fa-quote-left text-primary mr-2" aria-hidden="true"></i>
                      <br />
                      {t.title}
                      <i class="fa fa-quote-right text-primary ml-2" aria-hidden="true"></i>{" "}
                    </h3>
                    <h3
                      className="text-white text-700"
                      style={{ fontSize: size ? "20px" : "16px" }}
                    >
                      {t.by}
                    </h3>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;

const testimonials = [
  {
    title:
      "It was a pleasure sharing and learning insights from top banking leaders and the organisation of the summit was a success. I would love to participate in Finnovex again in their future editions ",
    by: "Benchouia Ahmed Abdelhakim | Deputy General Manager IT & Digital (CDO), Banque Al Baraka d’Algerie",
  },

  {
    title:
      "Awesome experience huge energy great learnings from a lot of banking delegates and i must say it is an innovative place to learn new ideas coming about technology new initiatives coming across the globe really been privileged to share and meet with some of the industry leaders and get to aligned with their vision and learn more about their journey. Thank you very much Finnovex",
    by: "Dr. Ahmed Mansour | Chief Technology Officer, Abu Dhabi Commercial Bank Egypt",
  },

  {
    title:
      "Congratulations on a wonderfully conducted event, wonderful keynotes, brillant panel discussions and we had a wonderful workshop as well and we had terrific conversations across the board with many eminent personalities from the region so it’s been an overwhelmingly positive experience. Looking forward to Finnovex 2025",
    by: "Waleed Al Assuti | Chief Information Officer, Banque Misr, Egypt",
  },
  {
    title:
      "It’s a great honour to participate at Finnovex North Africa this year with thought leaders, innovators and the experts in the financial and technology technology talking about the latest trends insights and informative sessions about the banking challenges, how to overcome these challenges and talking about the future of banking. Thank you so much for the invite i will definitely be attending this every year",
    by: "Sara Walied | Innovation Manager, Credit Agricole Egypt",
  },
  {
    title:
      "So overall it’s a great opportunity and especially for the startups that want to scale in the Egypt. It will be imperative to consider participation in this conference moving forward.",
    by: "Azizjon Azimi | Founder and CEO,zypl.ai",
  },
];
