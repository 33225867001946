import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import { size } from "./common";
import Slider from "react-slick";
import anime from "animejs";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S4 from "./svgs/s4";
import S5 from "./svgs/s5";
import S6 from "./svgs/s6";
import S7 from "./svgs/s7";
import S8 from "./svgs/s8";
import S9 from "./svgs/s9";
import S10 from "./svgs/s10";
import S11 from "./svgs/s11";

function Spotlight() {
  let pageHeader = React.createRef();
  const [active, setActive] = useState(false);

  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const settings1 = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    centerPadding: "10px",
    // swipeToSlide: true,
    centerMode: true,
    className: "p-10",
    beforeChange: (current, next) => setActive(next),
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const content = [
    {
      title: "Digital Transformation for Financial Inclusion",
      description: "Empowering North Africa’s Underbanked",
      image: <S10 active={active} />,
    },
    {
      title: "Sustainable Finance in North Africa",
      description: " Driving Growth through Green Innovation",
      image: <S1 active={active} />,
    },
    {
      title: "AI-Driven Financial Services",
      description: "Unlocking the Future of Intelligent Banking",
      image: <S2 active={active} />,
    },
    {
      title: "Crafting Customer-Centric Financial Solutions",
      description: "The Role of Digital Transformation",
      image: <S3 active={active} />,
    },
    {
      title: "Cloud-Native Banking",
      description: "Revolutionizing Infrastructure for Scalability and Resilience",
      image: <S4 active={active} />,
    },
    {
      title: "Next-Gen Payments Technologies ",
      description: "Forging the Path to Future Finance",
      image: <S5 active={active} />,
    },
    {
      title: "Open Banking and Fintech Collaboration",
      description: "Catalysts for Financial Innovation",
      image: <S6 active={active} />,
    },
    {
      title: "Cross-Border Payments Innovation",
      description: "Simplifying Transactions Across North Africa",
      image: <S7 active={active} />,
    },
    {
      title: "Hyper-Personalization in Finance",
      description: "Leveraging Data to Tailor Customer Journeys",
      image: <S8 active={active} />,
    },
    {
      title: "Next-Gen Neobanks",
      description: "Leading the Digital Disruption in North Africa",
      image: <S9 active={active} />,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {/* <div className="overlay-primary" /> */}
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="p-4">
              <ScrollAnimation
                animateIn={"fadeInUp"}
                animateOnce={true}
                duration={1.5}
                // afterAnimatedIn={function afterAnimatedIn(v) {
                //   setActive(true);
                // }}
              >
                <h1 className="text-700 text-center text-white mt-0 text-uppercase">
                  Spotlight Topics
                  <br />
                </h1>
              </ScrollAnimation>
              <br />
            </Col>
          </Row>
          <div className={`justify-content-center row ${size ? "row-cols-5" : "row-cols-2"}`}>
            {content.map((s, index) => (
              <Col className="text-center  p-2">
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={1.5 + index / 8}
                  afterAnimatedIn={function afterAnimatedIn(v) {
                    setActive(true);
                  }}
                >
                  <div className="pt-4 px-2 stat-div" style={{ minHeight: 270 }}>
                    {s.image}

                    <h3
                      style={{
                        color: "#fff",
                        fontSize: size ? "18px" : "16px",
                      }}
                      className="text-700 mt-4"
                    >
                      {s.title}
                    </h3>
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: size ? "16px" : "14px",
                      }}
                      className="text-400 mt-0"
                    >
                      {s.description}
                    </h3>
                  </div>
                </ScrollAnimation>
              </Col>
            ))}
          </div>
        </Container>
      </div>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row style={{}} className="justify-content-center">
            <Col lg={12} className="p-4">
              <h1 className="text-700 text-center text-white mt-0 text-uppercase">Trusted by</h1>
              <h5 className="text-400 mt-0 text-white text-center">
                We host some of the community’s most successful and influential organizations and
                solutions providers.
              </h5>
            </Col>
          </Row>
          {size ? (
            <Row className={`text-700 justify-content-center `} style={{ marginTop: "-1rem" }}>
              {sponsors.map((data, i) => (
                <Col className={"mx-auto"} key={i} xs={6} lg={2}>
                  <div
                    style={{
                      display: "flex",
                      aspectRatio: "2/1",
                      justifyContent: "center",
                      background: "#fff",
                      padding: "1em",
                    }}
                    className="stat-div"
                  >
                    <img
                      src={require(`assets/images/sponsors/${data.image}`)}
                      style={{
                        maxWidth: "100%",
                        alignSelf: "center",
                      }}
                      alt={data.name}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <Row className={`text-700 justify-content-center `} style={{ marginTop: "1rem" }}>
              <Col sm={11} xs={11} className={"ml-auto mr-auto"}>
                <Slider {...settings}>
                  {sponsors.map((data, i) => (
                    <div
                      style={{
                        justifyContent: "center",
                        // background: "#fff",
                        padding: "1em",
                      }}
                    >
                      <img
                        src={require(`assets/images/sponsors/${data.image}`)}
                        style={{
                          width: "100%",
                          // height: "150px",
                          alignSelf: "center",
                        }}
                        alt={data.name}
                      />
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
}

export default Spotlight;

export const sponsors = [
  { image: "microsoft.png" },
  { image: "hcl.jpg" },

  { image: "infosys.jpg" },

  { image: "tata.jpeg" },
  { image: "oracle.jpeg" },
  { image: "pwc.jpg" },
];
