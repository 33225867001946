import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function WhoSponsor() {
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          // backgroundImage: `url(${svg})`,
          // backgroundPosition: "top right",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="my-5">
              <h5 className="text-400 text-white mt-0">
                Are you prepared to position your brand to new heights? Our seamless 3-step
                sponsorship process allows you to customize your experience, engage with key
                stakeholders, and establish your position as a leader in innovation and
                transformation. From tailored packages to exclusive networking opportunities, this
                is your chance to connect with decision-makers and create a lasting impression in
                one of the world’s most dynamic economies. Don’t miss this opportunity to showcase
                your solutions and drive meaningful impact. Secure your spot today and let your
                brand shine!
              </h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-white mt-3 text-700" style={{ color: "#003972" }}>
                Top Reasons To Sponsor
              </h2>
            </Col>
            {content.map((s, i) => (
              <Col key={s.title} lg={12} xs={12} className="">
                <div className="container-fluid row">
                  <Col lg={6}>
                    <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
                      <hr />
                      <h3 className="text-white text-700">{s.title}</h3>
                      <h5 className="text-white text-400 mt-2">{s.description}</h5>
                    </ScrollAnimation>
                  </Col>
                  <Col lg={6} className="mt-3">
                    <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
                      <img
                        src={require(`assets/quotes/${i + 1}.jpg`)}
                        width="100%"
                        alt="main logo"
                      />
                    </ScrollAnimation>
                  </Col>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col md={12} xs={12} className="mb-1 mt-3">
              <Container fluid>
                <Row>
                  <Col lg={4} xs={12} className={`px-1`}>
                    <Button className="px-3 py-2 my-5 rounded-0" color="primary" href="/register">
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        Get a Sponsorship Kit
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="mt-5 text-center p-3 mb-4">
              <h5 className="text-400 mt-0 text-white p-3">
                Ready to make an impact in North Africa? Sponsor North Africa- it’s a chance to
                strategically position your brand at the forefront of North Africa’s booming
                financial industry. Engage with top-tier executives, showcase your FinTech
                solutions, and build meaningful relationships that can drive long-term growth for
                your business.
                <br />
                <br />
                Contact us today at{" "}
                <a href="mailto:info@exibex.com" className="text-primary">
                  <b>info@exibex.com</b>
                </a>{" "}
                or call us on{" "}
                <a href="tel:+971 585518437" className="text-primary">
                  <b>+971 585518437</b>
                </a>
                to learn more. Our team is ready to help you seize this opportunity and make a
                powerful impact in your industry. Let's take your brand to the top together!
              </h5>

              <br />
              <Container>
                <Row className="justify-content-center">
                  <Col lg={4}>
                    <Button
                      href="mailto:info@exibex.com"
                      className="btn my-2 text-center px-5"
                      color="primary"
                      size="lg"
                    >
                      Contact Us
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WhoSponsor;

const content = [
  {
    title: "Direct Access to Decision-Makers",
    description: `Connect with 200+ influential leaders shaping North Africa's financial sector. Build meaningful relationships with key decision-makers.`,
    image: "decision.png",
  },
  {
    title: "Host Exclusive Demos and Showcase Solutions",
    description: ` Gain direct access to a highly targeted audience by demonstrating your cutting-edge solutions in the Live Demo Zone. Let your products and services take the spotlight, creating lasting impressions and fostering real-time interactions.`,
    image: "competition.png",
  },
  {
    title: "Access Industry Knowledge",
    description: `Navigate North Africa’s rapidly evolving banking and FinTech landscape with expert knowledge and tailored solutions.`,
    image: "one.png",
  },
  {
    title: "Gain Post-Event Insights and Continued Engagement",
    description: `Extend the value of your sponsorship with access to post-event analytics, attendee feedback, and insights. Stay connected with the audience long after the summit concludes, ensuring sustained engagement and ROI.`,
    image: "showcase.png",
  },
];
