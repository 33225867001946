import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Navigate Digital Transformation and Sustainable Innovation",
      description:
        "Transformation extends beyond technology to create a more inclusive, resilient, and sustainable financial system. Learn how AI, advanced analytics, and personalized platforms are redefining customer experiences with frictionless and intuitive services.",
      image: <S2 />,
    },
    {
      title: "Cybersecurity in the Digital Era",
      description:
        "As the risk of cyberattacks escalates, gain insights into cutting-edge cybersecurity strategies and technologies to safeguard financial data and ensure trust in an interconnected ecosystem.",
      image: <S3 />,
    },
    {
      title: "Cross-Border Fintech Regulation",
      description:
        "Unpack the complexities of harmonizing global standards to foster financial inclusion and streamline cross-border fintech operations in a rapidly globalizing market.",
      image: <S8 />,
    },
  ];

  const content1 = [
    {
      title: "Insights",
      description:
        "Finnovex North Africa 2025 focuses on delivering actionable insights to help financial leaders navigate digital transformation and sustainability. Through keynotes, case studies, and data-driven presentations, attendees will gain strategies for future-proofing operations, enhancing customer experiences, and staying competitive in an evolving market.",
    },
    {
      title: "Networking",
      description:
        "Finnovex is about fostering meaningful connections. Curated networking sessions, private meetings, and partnerships will help financial institutions, fintech innovators, and government leaders collaborate, explore investment opportunities, and grow their business networks in an exclusive environment.",
    },
    {
      title: "Spotlight",
      description:
        "Elevate your brand visibility at Finnovex North Africa 2025 and connect your organization to a broad audience through Finnovex’s diverse media channels. Engage with decision-makers in personalized networking sessions, sponsor demos, and insightful presentations, all designed to elevate your brand visibility..",
    },
    {
      title: "Exclusive Roundtable Strategy Sessions",
      description:
        "At the heart of Finnovex North Africa 2025, these elite roundtable sessions bring together the region's top CEOs, C-suite executives, and strategic leaders to tackle the most urgent financial challenges. In these intimate, closed-door discussions, you'll forge powerful alliances, share game-changing insights, and define the future of business growth and economic transformation in North Africa.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            // backgroundImage: "url(" + require("assets/images/asset.png") + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={11}>
                {" "}
                <span className="text-white"></span>
                <h2 className="text-500 text-white">
                  Navigating the Future of Finance through
                  <b> Digital Transformation and Sustainable Innovation</b>{" "}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  As the financial sector faces the dual imperatives of digital transformation and
                  sustainable innovation. The 29th Edition of Finnovex North Africa, set for April
                  23, 2025, in Cairo, Egypt, is a premier gathering of visionary leaders,
                  innovators, and policymakers from across the region. With the theme “Navigating
                  the Future of Finance through Digital Transformation and Sustainable Innovation,”
                  the summit aims to redefine the financial landscape by fostering impactful
                  conversations and collaborative solutions.
                  <br />
                  <br />
                  This landmark event will spotlight how financial institutions can leverage
                  cutting-edge technology to accelerate innovation, embed sustainability into their
                  core operations, and create a resilient ecosystem for businesses and society
                  alike.
                  <br />
                  <br />
                </h5>
              </Col>
              <Col lg={6} className="mt-5">
                <div className="container-fluid row">
                  {content.map((s, index) => (
                    <Col className="text-center  p-2" lg={12}>
                      <ScrollAnimation
                        animateIn={"fadeInUp"}
                        animateOnce={true}
                        duration={1.5 + index / 8}
                      >
                        <div className="pt-4 px-2 stat-div pb-2" style={{ minHeight: 270 }}>
                          {s.image}

                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                            }}
                            className="text-700 mt-4"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </div>
                      </ScrollAnimation>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "42px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  What Makes Finnovex Unique? <br />
                  Envisioning North Africa’s Financial Sector
                </h3>
                <br />
                <br />
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 mb-5">
                  <Col lg={6} className="align-self-center">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "34px",
                      }}
                      className="text-400 mt-0 mb-2"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-0 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6}>
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/images/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "1.jpeg",
  "2.jpeg",
  "3.jpeg",
  "4.jpeg",
  "5.jpeg",
  "6.jpeg",
  "7.jpeg",
  "8.jpeg",
  "9.jpeg",
];
